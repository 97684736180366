import './Home.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button, Card, Form} from "react-bootstrap";
import hotelreinigung from './home.jpg'
// @ts-ignore
import Fade from "react-reveal/Fade";
import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import emailjs from "@emailjs/browser";

function Home() {
    const formRef = useRef(null);
    const [from_name, setEmail] = useState('');
    const [message, setTextInput] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        let toastTimer: NodeJS.Timeout;
        if (showToast) {
            toastTimer = setTimeout(() => {
                setShowToast(false);
            }, 5000);
        }
        return () => clearTimeout(toastTimer);
    }, [showToast]);

    // @ts-ignore
    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true);


        emailjs
            // @ts-ignore
            .sendForm('ionos_smtp_ausgang', 'template_inbox', formRef.current, 'pgU3RzTGqgP5eZ05w')
            .then(
                (result) => {
                    console.log(result.text);
                    setIsSubmitting(false);
                    setEmail('');
                    setTextInput('');
                    setShowToast(true);
                    // @ts-ignore
                    formRef.current.reset(); // Reset the form after successful submission
                },
                (error) => {
                    console.log(error.text);
                    setIsSubmitting(false);
                }
            );
    };
    return (
        <div>
            <div className="home-container">
                <div className="white-overlay"/>
                <Fade right duration={1500}>
                    <h1 className="title-heading">
                        Erleben Sie <br/>
                        professionelle Reinigung
                    </h1>
                    <h3 className="sub-heading">
                        Saubere und gepflegte Räume für Ihren Komfort. Vertrauen Sie <br/>
                        auf unsere Expertise
                    </h3>
                    <Link to="/kontakt">
                        <Button className="booking">
                            Jetzt buchen
                        </Button>
                    </Link>
                </Fade>
            </div>


            <Container className="services">
                <Row>
                    <h1 className="services-heading">
                        Dienstleistungen
                    </h1>

                </Row>
                <Row>
                    <Col>
                        <Fade>
                            <Link to="/reinigung/hotel">
                                <Card className="card-tile">
                                    <div className="image-overlay"></div>
                                    <h4 className="kachel-title d-none d-md-block">Hotelreinigung</h4>
                                    <h4 className="kachel-title d-md-none">Hotel</h4>
                                    <Card.Img variant="top" src={hotelreinigung}/>
                                </Card>
                            </Link>
                        </Fade>

                    </Col>
                    <Col>
                        <Fade>
                            <Link to="/reinigung/gebaeude">
                                <Card className="card-tile">
                                    <div className="image-overlay"></div>
                                    <h4 className="kachel-title d-none d-md-block">Gebäudereinigung</h4>
                                    <h4 className="kachel-title d-md-none">Gebäude</h4>
                                    <Card.Img variant="top" src={hotelreinigung}/>
                                </Card>
                            </Link>
                        </Fade>
                    </Col>
                    <Col>
                        <Fade>
                            <Link to="/reinigung/praxis">
                                <Card className="card-tile">
                                    <div className="image-overlay"></div>
                                    <h4 className="kachel-title d-none d-md-block">Praxisreinigung</h4>
                                    <h4 className="kachel-title d-md-none">Praxis</h4>
                                    <Card.Img variant="top" src={hotelreinigung}/>
                                </Card>
                            </Link>
                        </Fade>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Fade>
                            <Link to="/reinigung/fenster">
                                <Card className="card-tile">
                                    <div className="image-overlay"></div>
                                    <h4 className="kachel-title d-none d-md-block">Fensterreinigung</h4>
                                    <h4 className="kachel-title d-md-none">Fenster</h4>
                                    <Card.Img variant="top" src={hotelreinigung}/>
                                </Card>
                            </Link>
                        </Fade>
                    </Col>
                    <Col>
                        <Fade>
                            <Link to="/reinigung/grund">
                                <Card className="card-tile">
                                    <div className="image-overlay"></div>
                                    <h4 className="kachel-title d-none d-md-block">Grundreinigung</h4>
                                    <h4 className="kachel-title d-md-none">Grund</h4>
                                    <Card.Img variant="top" src={hotelreinigung}/>
                                </Card>
                            </Link>
                        </Fade>
                    </Col>
                    <Col>
                        <Fade>
                            <Link to="/reinigung/bau">
                                <Card className="card-tile">
                                    <div className="image-overlay"></div>
                                    <h4 className="kachel-title d-none d-md-block">Baureinigung</h4>
                                    <h4 className="kachel-title d-md-none">Bau</h4>
                                    <Card.Img variant="top" src={hotelreinigung}/>
                                </Card>
                            </Link>
                        </Fade>
                    </Col>
                </Row>
            </Container>

            <Container className="about margin-bot">
                <Row>
                    <Fade duration={700} delay={200}>
                        <h1 className="services-heading text-sm-40">
                            Über uns
                        </h1>
                    </Fade>
                </Row>
                <Row style={{alignItems: 'center'}}>
                    <Col className="heading-col">

                        <Fade duration={700} delay={500}>
                            <p>
                                ProAktiv-Reinigung ist ein führendes Unternehmen im Bereich Hotel- und Gebäudereinigung.
                                Mit
                                Sitz in Uhldingen-Mühlhofen. Deutschland, bieten wir professionelle
                                Reinigungsdienstleistungen für Hotels und Gebäude an.
                            </p>
                            <p>
                                Unser engagiertes Team von Reinigungsexperten arbeitet mit höchster Effizienz und
                                Sorgfalt.
                                um sicherzustellen, dass Ihre Räumlichkeiten stets sauber und einladend sind. Wir legen
                                großen Wert auf Qualität. Zuverlässigkeit und Kundenzufriedenheit.
                                Vertrauen Sie uns Ihren Reinigungsbedürfnissen an und lassen Sie uns für eine saubere
                                und
                                hygienische Umgebung sorgen.
                            </p>
                        </Fade>
                    </Col>
                    <Col className="d-none d-lg-block">
                        <Fade duration={700} delay={1000}>
                            <Card className="card-tile">
                                <Card.Img variant="top" src={hotelreinigung}/>
                            </Card>
                        </Fade>
                    </Col>
                </Row>
            </Container>

            <Container style={{marginTop: '60px'}}>
                <Row>
                    <Col style={{marginTop: '90px'}}>
                        <Fade duration={700} delay={200}>
                            <h1 className="contact-heading text-sm-40">
                                Kontaktaufnahme
                            </h1>
                            <p >
                                Haben Sie Fragen oder möchten Sie ein Angebot anfordern? <br/>
                                Wir freuen uns auf Ihre Nachricht!
                            </p>
                        </Fade>
                    </Col>
                    <Col>
                        <Fade duration={700} delay={500}>
                        <Form ref={formRef} onSubmit={sendEmail}>
                            <Row style={{marginBottom: '30px'}}>
                                <Col>
                                    <Form.Group controlId="formBasicEmail" style={{maxWidth: '400px'}}>
                                        <Form.Label style={{fontFamily: 'Medium', fontSize: '20px', marginBottom: '0.1rem'}}>
                                            E-Mail Adresse
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            name="from_name"
                                            type="email"
                                            placeholder=""
                                            value={from_name}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <Form.Text className="text-muted"></Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '30px'}}>
                                <Col>
                                    <Form.Group controlId="message">
                                        <Form.Label style={{
                                            fontFamily: 'Medium',
                                            fontSize: '20px',
                                            marginBottom: '0.1rem'
                                        }}>Nachricht</Form.Label>
                                        <Form.Control style={{maxWidth: '800px'}}
                                                      required
                                                      name="message"
                                                      type="text"
                                                      value={message}
                                                      as="textarea"
                                                      rows={5}
                                                      onChange={(e) => setTextInput(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '10px'}}>
                                <Col style={{maxWidth: '10px'}}>
                                    <Form.Check required style={{paddingTop: '3px'}}
                                                type="checkbox"
                                                label=""
                                    />
                                </Col>
                                <Col>
                                    <p style={{marginLeft: '7px'}}>Ich akzeptiere die <Link to="/datenschutz">Datenschutzbestimmungen</Link></p>
                                </Col>
                            </Row>
                            <Button style={{
                                backgroundColor: 'rgba(219,108,68, 1)',
                                color: 'white',
                                fontFamily: 'Medium',
                                fontSize: '19px',
                                marginBottom: '30px',
                            }} variant="" type="submit" disabled={isSubmitting}>
                                {isSubmitting ? 'Einen Moment...' : 'Senden'}
                            </Button>
                        </Form>
                        </Fade>
                        <Toast show={showToast} onClose={() => setShowToast(false)} style={{
                            backgroundColor: 'rgba(152,255,152,1)',
                            position: 'absolute',
                            top: '5rem',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            minWidth: '200px', // Adjust width as needed
                            zIndex: 9999, // Ensure it appears above other content
                        }}>
                            <Toast.Header style={{paddingBottom: '0px', backgroundColor: 'rgba(152,255,152,1)'}}>
                                <img src="holder.js/20x20?text=%20" className="rounded me-2" alt=""/>
                                <strong className="me-auto" style={{fontFamily: 'Medium', fontSize: '22px'}}>Vielen Dank!</strong>
                            </Toast.Header>
                            <Toast.Body style={{fontFamily: 'Medium', fontSize: '19px',}}>Ihre Nachricht is angekommen!</Toast.Body>
                        </Toast>
                    </Col>
                </Row>


            </Container>
        </div>
    )
}

export default Home;