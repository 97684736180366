import React from 'react';
import './Bigtitle.css';
// @ts-ignore
import Fade from "react-reveal/Fade";


// @ts-ignore
const Bigtitle = ({ heading }) => {
    return (
        <div className="custom-container">
            <div className="white-overlay" />
            <Fade right>
                <h1 className="custom-heading text-center">{heading}</h1>
            </Fade>


        </div>
    );
};

export default Bigtitle;
