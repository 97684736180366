import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Card} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import React, {ReactElement, JSXElementConstructor, ReactNode, ReactPortal, Key, useEffect} from "react";
import './Building.css';
import Bigtitle from "../Bigtitle/Bigtitle";
// @ts-ignore
import Fade from "react-reveal/Fade";




// @ts-ignore
const Building = ({mainHeading, introText, listItems1, listItems2}) => {
    // @ts-ignore
    return <div class="">
        <Bigtitle heading={mainHeading}/>


        <Row className="hotel">
            <Fade right delay={100} duration={1600}>
                <Card className="hotel-intro-card card-color">
                    <div style={{width: '80vw', padding: '30px 50px 30px 50px'}} className="text-card text-center">
                        <p style={{padding: '10px'}}>{introText}</p>
                    </div>
                </Card>
            </Fade>
        </Row>

            <Row className="margin-bot">
                <Col className="first-col" style={{display: 'flex', justifyContent: 'center' }}>
                    <Fade right delay={600} duration={1600}>
                        <Card className="p-3 inner-card text-center">
                            <ul>
                                {listItems1.map((item: { heading: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; content: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }, index: Key | null | undefined) =>
                                    <li key={index}>
                                        <h4>{item.heading}</h4>
                                        <p>{item.content}</p>
                                    </li>)}
                            </ul>
                        </Card>
                    </Fade>
                </Col>
                <Col className="second-col px-4" style={{display: 'flex', justifyContent: 'center'}}>
                    <Fade right delay={800} duration={1500}>
                        <Card className="p-3 inner-card second-card text-center">
                            <ul>
                                {listItems2.map((item: { heading: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; content: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }, index: Key | null | undefined) =>
                                    <li key={index}>
                                        <h4>{item.heading}</h4>
                                        <p>{item.content}</p>
                                    </li>)}
                            </ul>
                        </Card>
                    </Fade>
                </Col>
            </Row>



    </div>;
};

Building.propTypes = {
    mainHeading: PropTypes.string.isRequired,
    introText: PropTypes.string.isRequired,
    listItems1: PropTypes.arrayOf(
        PropTypes.shape({
            heading: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        })
    ).isRequired,
    listItems2: PropTypes.arrayOf(
        PropTypes.shape({
            heading: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Building;
