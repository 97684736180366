import React, {useEffect, useState} from 'react';
import {Button, Card, Form, ToastHeader} from "react-bootstrap";
import {useRef} from "react";
import emailjs from '@emailjs/browser';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Toast from 'react-bootstrap/Toast';
import {Link} from 'react-router-dom'

const Contact = () => {
    const formRef = useRef(null);
    const [from_name, setEmail] = useState('');
    const [message, setTextInput] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        let toastTimer: NodeJS.Timeout;
        if (showToast) {
            toastTimer = setTimeout(() => {
                setShowToast(false);
            }, 5000);
        }
        return () => clearTimeout(toastTimer);
    }, [showToast]);

    // @ts-ignore
    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true);


        emailjs
            // @ts-ignore
            .sendForm('ionos_smtp_ausgang', 'template_inbox', formRef.current, 'pgU3RzTGqgP5eZ05w')
            .then(
                (result) => {
                    console.log(result.text);
                    setIsSubmitting(false);
                    setEmail('');
                    setTextInput('');
                    setShowToast(true);
                    // @ts-ignore
                    formRef.current.reset(); // Reset the form after successful submission
                },
                (error) => {
                    console.log(error.text);
                    setIsSubmitting(false);
                }
            );
    };

    return (
        <Container style={{paddingTop: '50px'}}>
            <Row>
                <h1 className="text-center services-heading">Kontakt</h1>
            </Row>
            <Form ref={formRef} onSubmit={sendEmail}>
                <Row style={{marginBottom: '30px'}}>
                    <Col>
                        <Form.Group controlId="formBasicEmail" style={{maxWidth: '400px'}}>
                            <Form.Label style={{fontFamily: 'Medium', fontSize: '25px', marginBottom: '0.1rem'}}>
                                E-Mail Adresse
                            </Form.Label>
                            <Form.Control
                                required
                                name="from_name"
                                type="email"
                                placeholder=""
                                value={from_name}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row style={{marginBottom: '30px'}}>
                    <Col>
                        <Form.Group controlId="message">
                            <Form.Label style={{
                                fontFamily: 'Medium',
                                fontSize: '25px',
                                marginBottom: '0.1rem'
                            }}>Nachricht</Form.Label>
                            <Form.Control style={{maxWidth: '800px'}}
                                required
                                name="message"
                                type="text"
                                value={message}
                                as="textarea"
                                rows={5}
                                onChange={(e) => setTextInput(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row style={{marginBottom: '30px'}}>
                    <Col style={{maxWidth: '10px'}}>
                        <Form.Check required style={{paddingTop: '3px'}}
                                    type="checkbox"
                                    label=""
                        />
                    </Col>
                    <Col>
                        <p style={{marginLeft: '7px'}}>Ich akzeptiere die <Link to="/datenschutz">Datenschutzbestimmungen</Link></p>
                    </Col>
                </Row>
                <Button style={{
                    backgroundColor: 'rgba(219,108,68, 1)',
                    color: 'white',
                    fontFamily: 'Medium',
                    fontSize: '19px',
                }} variant="" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Einen Moment...' : 'Senden'}
                </Button>
            </Form>
            <Toast show={showToast} onClose={() => setShowToast(false)} style={{
                backgroundColor: 'rgba(152,255,152,1)',
                position: 'absolute',
                top: '5rem',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                minWidth: '200px', // Adjust width as needed
                zIndex: 9999, // Ensure it appears above other content
            }}>
                <Toast.Header style={{paddingBottom: '0px', backgroundColor: 'rgba(152,255,152,1)'}}>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt=""/>
                    <strong className="me-auto" style={{fontFamily: 'Medium', fontSize: '22px'}}>Vielen Dank!</strong>
                </Toast.Header>
                <Toast.Body style={{fontFamily: 'Medium', fontSize: '19px',}}>Ihre Nachricht is angekommen!</Toast.Body>
            </Toast>
        </Container>
    );
};

export default Contact;