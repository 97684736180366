import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Navigation.css';
import {Button} from "react-bootstrap";
import {HashRouter} from "react-router-dom";
import {Routes, Route, Link} from "react-router-dom";
import Home from "../Home/Home";
import Building from "../Building/Building";
import Impressum from "../Impressum/Impressum";
import Contact from "../Contact/Contact";
import logo from './Logo.jpg';
import React, {useState} from "react";
import Datenschutz from "../Datenschutz/Datenschutz";
import Footer from "../Footer/Footer";

function Navigation() {
    const [expanded, setExpanded] = useState(false);

    const baureinigungData = {
        mainHeading: "Baureinigungsdienste",
        introText:
            "Baureinigungsdienste sind spezialisierte Reinigungsdienstleistungen, die nach Abschluss von Bauarbeiten durchgeführt werden, um die Baustelle oder das Gebäude für die Nutzung vorzubereiten. Diese Dienste umfassen eine gründliche Reinigung und Beseitigung von Bauschutt, Staub, Schmutz und anderen Rückständen, die während des Bauprozesses entstanden sind.",
        listItems1: [
            {
                heading: "Bauschuttenfernung",
                content: "Entfernung von Bauschutt und groben Verschmutzungen",
            },
            {
                heading: "Böden, Fenster, Oberflächen",
                content: "Reinigung von Böden, Fenstern und Oberflächen",
            },
            {
                heading: "Sanitäranlagen",
                content: "Säuberung der Sanitäranlagen",
            },
        ],
        listItems2: [
            {
                heading: "Abfallentsorgung",
                content: "Entsorgung von Abfall und Restmaterialien",
            },
            {
                heading: "Baustellenschilder",
                content: "Entfernung von Baustellenschildern und Warnhinweisen",
            },
            {
                heading: "Spezialreinigung",
                content: "Optionale Spezialreinigungen, z.B. Reinigung von Glasfassaden",
            },
        ],
    };

    const unterhaltsreinigungData = {
        mainHeading: "Unterhaltsreinigung",
        introText:
            "Unterhaltsreinigungsdienste beziehen sich auf regelmäßige Reinigungsleistungen, die in wiederkehrenden Intervallen durchgeführt werden, um die Sauberkeit und Ordnung von Räumlichkeiten aufrechtzuerhalten. Solche Reinigungen können für verschiedene Arten von Einrichtungen und Gebäuden gelten, einschließlich Büros, Wohnungen, Geschäfte, Schulen, medizinische Einrichtungen und mehr.",
        listItems1: [
            {
                heading: "Bodenreinigung",
                content: "inkl. Staubsaugen, Kehren und Wischen",
            },
            {
                heading: "Arbeits-/Oberflächen",
                content: "Reinigung von Ober- und Arbeitsflächen",
            },
            {
                heading: "Abfallentsorgung",
                content: "Leerung von Mülleimern und Entsorgung des Abfalls",
            },
            {
                heading: "Glas-/Spiegelreinigung",
                content: "Reinigung von Glasflächen und Spiegeln",
            },
        ],
        listItems2: [
            {
                heading: "Sanitäranlagen",
                content: "Reinigung und Desinfektion von Sanitäranlagen",
            },
            {
                heading: "Küchen- und Essbereiche",
                content: "Reinigung der Küchen- und Essbereiche",
            },
            {
                heading: "Abstauben",
                content: "von Möbeln, Lampen und anderen Gegenständen",
            },
        ],
    };

    const grundreinigungData = {
        mainHeading: "Grundreinigung",
        introText:
            "Grundreinigungsdienste beziehen sich auf eine umfassende und gründliche Reinigung von Räumlichkeiten. Es handelt sich dabei oft um eine einmalige oder seltene durchgeführte Reinigung, die intensiver ist als die regelmäßige Unterhaltsreinigung.",
        listItems1: [
            {
                heading: "Intensive Bodenreinigung",
                content: "z.B. Teppiche, Fliesen, Holz",
            },
            {
                heading: "Fensterreinigung",
                content: "Säuberung von Fenstern, Rahmen und Fensterbänken",
            },
            {
                heading: "Tiefenreinigung Sanitär",
                content: "z.B. Kalkentfernung, Desinfektion",
            },
            {
                heading: "Fleckenentfernung",
                content: "Entfernung von hartnäckigen Flecken und Verschmutzungen",
            },
        ],
        listItems2: [
            {
                heading: "Schwer zugängliche Bereiche",
                content: "z.B. hinter Möbel",
            },
            {
                heading: "Küchen- und Essbereiche",
                content: "Grundreinigung der Küchen- und Essbereiche",
            },
            {
                heading: "Abstauben",
                content: "von Möbeln, Lampen und anderen Gegenständen",
            },
        ],
    };

    const fensterreinigungData = {
        mainHeading: "Glas- & Fensterreinigung",
        introText:
            "Glas- und Fensterreinigungsdienste sind Dienstleistungen, die sich auf die professionelle Reinigung von Fenstern und Glasflächen in verschiedenen Umgebungen konzentrieren. Diese Dienstleistungen können für Privathaushalte, gewerbliche Gebäude, Büros, Einkaufszentren und andere Einrichtungen angeboten werden.",
        listItems1: [
            {
                heading: "Fensterreinigung",
                content: "Reinigung von Fenstern (innen und außen)",
            },
            {
                heading: "Glasfassaden und Glaswände",
                content: "Reinigung von Glasfassaden und Glaswänden",
            },
            {
                heading: "Schmutzentfernung",
                content: "Entfernung von Fingerabdrücken, Schmutz und Verschmutzungen",
            },
            {
                heading: "Fensterrahmen und Fensterbretter",
                content: "Reinigung von Fensterrahmen und Fensterbrettern",
            },
        ],
        listItems2: [
            {
                heading: "Spiegel & Glasoberflächen",
                content: "Reinigung von Spiegeln und anderen Glasoberflächen",
            },
            {
                heading: "Klebereste",
                content: "Entfernung von Aufklebern oder Kleberesten",
            },
            {
                heading: "Jalousien & Rollos",
                content: "optionale Reinigung von Jalousien oder Rollos",
            },
        ],
    };

    const gebaeudereinigungData = {
        mainHeading: "Gebäudereinigung",
        introText:
            "Gebäudereinigung bezieht sich auf die professionelle Reinigung von Gebäuden, unabhängig von ihrer Art oder Nutzung. Dies kann sowohl für private als auch gewerbliche Gebäude gelten. Die Gebäudereinigung umfasst eine breite Palette von Aufgaben, die darauf abzielen, die Sauberkeit, Hygiene und den allgemeinen Zustand eines Gebäudes aufrechtzuerhalten.",
        listItems1: [
            {heading: "Bodenreinigung", content: "inkl. Staubsaugen, Kehren und Wischen"},
            {heading: "Oberflächensäuberung", content: "wie Tische, Schreibtische und Theken"},
            {heading: "Sanitäranlagenreinigung", content: "wie Toiletten und Waschbecken"},
        ],
        listItems2: [
            {heading: "Müllentfernung", content: "Entfernen von Müll und Abfällen"},
            {heading: "Gemeinschaftsbereiche", content: "wie Treppenhäuser und Flure"},
            {heading: "Sonderreinigungen", content: "z.B. Teppichreinigung oder Grundreinigungen"},
        ],
    };

    const praxisreinigungData = {
        mainHeading: "Praxisreinigung",
        introText:
            "Praxisreinigungen beziehen sich auf Dienstleistungen, die speziell für die Reinigung von Arztpraxen und medizinischen Einrichtungen angeboten werden. Diese Art der Reinigung erfordert besondere Sorgfalt und Hygiene, um die Sicherheit und Gesundheit der Patienten und Mitarbeiter zu gewährleisten.",
        listItems1: [
            {
                heading: "Zimmerreinigung",
                content: "Wartezimmer, Behandlungsräume und Sanitäreinrichtungen",
            },
            {
                heading: "Oberflächendesinfektion",
                content: "insbesondere bei Bereichen mit hohem Kontakt",
            },
            {
                heading: "Sanitäranlagenreinigung",
                content: "wie Toiletten und Waschbecken",
            },
        ],
        listItems2: [
            {
                heading: "Bodenreinigung",
                content: "Staubsaugen, Wischen und Desinfizieren",
            },
            {
                heading: "Abfallentsorgung",
                content: "vorschriftsgemäße Entsorgung medizinischer Abfälle",
            },
            {
                heading: "Auffüllung",
                content: "von Hygieneartikeln (Seife und Handtücher)",
            },
        ],
    };
    const hausmeisterserviceData = {
        mainHeading: "Hausmeisterservice",
        introText:
            "Hausmeisterservice umfassen eine Vielzahl von Dienstleistungen, die darauf abzielen, die Pflege und Instandhaltung von Gebäuden, Wohnanlagen oder anderen Immobilien sicherzustellen. Diese Dienstleistungen können sowohl für private als auch für gewerbliche Immobilien angeboten werden.",
        listItems1: [
            {
                heading: "Reinigungsarbeiten",
                content: "Allgemeine Reinigungsarbeiten im Innen- und Außenbereich der Immobilie",
            },
            {
                heading: "Heizungs- & Sanitäranlagen",
                content: "Wartung von Heizungs- und Sanitäranlagen",
            },
            {
                heading: "Instandhaltung",
                content: "Kleinere Reparaturen und Instandhaltungsarbeiten",
            },
            {
                heading: "Organisation",
                content: "Organisation der Handwerkertermine",
            },
        ],
        listItems2: [
            {
                heading: "Außenbereiche",
                content: "Pflege der Grünanlagen und Außenbereiche",
            },
            {
                heading: "Winterdienst",
                content: "Schneeräumung und Streuung von Gehwegen",
            },
            {
                heading: "Gebäudetechnik",
                content: "Überwachung und Betreuung der Gebäudetechnik",
            },
            {
                heading: "Schlüsselverwaltung",
                content: "Verwaltung und Haltung der Schlüssel",
            },
        ],
    };

    const hotelreinigungData = {
        mainHeading: "Hotelreinigung",
        introText:
            "Die Hotelreinigungen können eine Vielzahl von Dienstleistungen umfassen, um sicherzustellen, dass Hotels sauber und einladend sind. Dazu gehört die Reinigung von Gästezimmern, öffentlichen Bereichen, Badezimmern und anderen Bereichen. Wir kümmern uns um die Reinigung der Zimmer, Badezimmer, Küche, öffentlichen Bereiche auch das Entfernen von Müll, das Wechseln von Bettwäsche und das Auffüllen von Toilettenartikeln umfassen. Unser erfahrenes geschultes Housekeepings-Personal sorgt dafür, dass Ihr Hotel immer sauber und einladend aussieht. Die professionelle Reinigung von Hotels kann dazu beitragen, dass sich Gäste wohl und geschätzt fühlen, was wiederum zu einer positiven Gästeerfahrung führt.",
        listItems1: [
            {
                heading: "Gästezimmer",
                content: "inkl. Staubsaugen, Betten machen, Betten & Badezimmer",
            },
            {
                heading: "Wäsche",
                content: "Bettwäsche & Handtücher",
            },
            {
                heading: "Gemeinschaftsbereiche",
                content: "z.B. Treppenhäusern & Fluren",
            },
        ],
        listItems2: [
            {
                heading: "Öffentliche Bereiche",
                content: "Empfangshalle, Lobby, Restaurants & Tagungsräume",
            },
            {
                heading: "Fenster",
                content: "sowohl von innen als auch außen",
            },
            {
                heading: "Sonderreinigungen",
                content: "z.B. Teppichreinigung oder Grundreinigungen",
            },
        ],
    };


    return (

        <HashRouter>
            <div className="wrapper">
                <div className="nav-font">
                    <Navbar expanded={expanded} expand="lg" className="nav-color" variant="dark" style={{backgroundColor: '#323840'}}>
                        <Container>
                            <Navbar.Brand href='/' className="logo-font"><span><img className="img-size"
                                                                                    src={logo}/></span>ProAktiv-Reinigung</Navbar.Brand>
                            <Navbar.Toggle  onClick={() => {
                                    setExpanded(!expanded);
                            }} aria-controls="basic-navbar-nav"/>
                            <Navbar.Collapse  id="basic-navbar-nav">
                                <Nav className="me-auto nav-margin">

                                    <Nav.Link  onClick={() => {
                                        if (expanded) {
                                            setExpanded(!expanded);
                                        }
                                    }} as={Link} to="/home">Home</Nav.Link>
                                    <Nav.Link  onClick={() => {
                                        if (expanded) {
                                            setExpanded(!expanded);
                                        }
                                    }} as={Link} to="reinigung/hotel">Hotelreinigung</Nav.Link>
                                    <NavDropdown title="Gebäudereinigung" id="basic-nav-dropdown">
                                        <NavDropdown.Item  onClick={() => {
                                            if (expanded) {
                                                setExpanded(!expanded);
                                            }
                                        }} as={Link}
                                                          to="reinigung/gebaeude">Gebäudereinigung</NavDropdown.Item>
                                        <NavDropdown.Item  onClick={() => {
                                            if (expanded) {
                                                setExpanded(!expanded);
                                            }
                                        }} as={Link}
                                                          to="reinigung/praxis">Praxisreinigung</NavDropdown.Item>
                                        <NavDropdown.Item  onClick={() => {
                                            if (expanded) {
                                                setExpanded(!expanded);
                                            }
                                        }} as={Link} to="reinigung/fenster">Glas &
                                            Fensterreinigung</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {
                                            if (expanded) {
                                                setExpanded(!expanded);
                                            }
                                        }} as={Link}
                                                          to="reinigung/grund">Grundreinigung</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {
                                            if (expanded) {
                                                setExpanded(!expanded);
                                            }
                                        }} as={Link}
                                                          to="reinigung/unterhalt">Unterhaltsreinigung</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => {
                                            if (expanded) {
                                                setExpanded(!expanded);
                                            }
                                        }} as={Link} to="reinigung/bau">Baureinigung</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link onClick={() => {
                                        if (expanded) {
                                            setExpanded(!expanded);
                                        }
                                    }} as={Link} to="/hausmeister">Hausmeisterservice</Nav.Link>
                                </Nav>


                                <Nav className="ml-auto">
                                    <Nav.Link as={Link} onClick={() => {
                                        if (expanded) {
                                            setExpanded(!expanded);
                                        }
                                    }}to="/kontakt"><Button className="contact-font" style={{
                                        backgroundColor: 'rgba(219,108,68, 1)',
                                        border: 'none'
                                    }}>Kontakt</Button></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
                <div>
                    <Routes>
                        <Route path="" element={<Home/>}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/reinigung/hotel" element={<Building
                            mainHeading={hotelreinigungData.mainHeading}
                            introText={hotelreinigungData.introText}
                            listItems1={hotelreinigungData.listItems1}
                            listItems2={hotelreinigungData.listItems2}
                        />}/>
                        <Route path="/reinigung/gebaeude"
                               element={<Building
                                   mainHeading={gebaeudereinigungData.mainHeading}
                                   introText={gebaeudereinigungData.introText}
                                   listItems1={gebaeudereinigungData.listItems1}
                                   listItems2={gebaeudereinigungData.listItems2}
                               />}/>
                        <Route path="/reinigung/fenster" element={<Building
                            mainHeading={fensterreinigungData.mainHeading}
                            introText={fensterreinigungData.introText}
                            listItems1={fensterreinigungData.listItems1}
                            listItems2={fensterreinigungData.listItems2}
                        />}/>
                        <Route path="/reinigung/grund" element={<Building
                            mainHeading={grundreinigungData.mainHeading}
                            introText={grundreinigungData.introText}
                            listItems1={grundreinigungData.listItems1}
                            listItems2={grundreinigungData.listItems2}
                        />}/>
                        <Route path="/reinigung/unterhalt" element={<Building
                            mainHeading={unterhaltsreinigungData.mainHeading}
                            introText={unterhaltsreinigungData.introText}
                            listItems1={unterhaltsreinigungData.listItems1}
                            listItems2={unterhaltsreinigungData.listItems2}
                        />}/>
                        <Route path="/reinigung/bau" element={<Building
                            mainHeading={baureinigungData.mainHeading}
                            introText={baureinigungData.introText}
                            listItems1={baureinigungData.listItems1}
                            listItems2={baureinigungData.listItems2}
                        />}/>
                        <Route path="/reinigung/praxis" element={<Building
                            mainHeading={praxisreinigungData.mainHeading}
                            introText={praxisreinigungData.introText}
                            listItems1={praxisreinigungData.listItems1}
                            listItems2={praxisreinigungData.listItems2}
                        />}/>
                        <Route path="/hausmeister" element={<Building
                            mainHeading={hausmeisterserviceData.mainHeading}
                            introText={hausmeisterserviceData.introText}
                            listItems1={hausmeisterserviceData.listItems1}
                            listItems2={hausmeisterserviceData.listItems2}
                        />}/>
                        <Route path="/kontakt" element={<Contact/>}/>
                        <Route path="/impressum" element={<Impressum/>}/>
                        <Route path="/datenschutz" element={<Datenschutz/>}/>
                    </Routes>
                </div>
            </div>
            <Footer/>

        </HashRouter>


    );


}

export default Navigation;