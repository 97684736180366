import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Card} from "react-bootstrap";
import React from "react";

function Impressum() {

    return (
        <div className="">
            <Container className="d-flex flex-column align-items-center">
                <Row>
                    <h1 style={{margin: '40px'}} className="services-heading">
                        Impressum
                    </h1>
                </Row>

                <Row className="impressum-card">
                    <Card style={{border: 'none'}} className="impressum-card">
                        <div style={{ width: '80vw' }} className="impressum-card">
                            <h3 style={{fontFamily: ''}}>
                                Angaben gemäß § 5 TMG
                            </h3>
                            <h5>
                                ProAktiv-Reinigung<br/>
                                Im Sieble 13<br/>
                                88690 Uhldingen-Mühlhofen
                            </h5>
                            <h3 className="font-roboto">
                                Vertreten durch:
                            </h3>
                            <h5>
                                Erkan Hatip
                            </h5>
                        </div>
                    </Card>

                </Row>
            </Container>
        </div>


    )
}

export default Impressum;