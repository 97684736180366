import React from 'react';
import './App.css';
import Navigation from "./Navigation/Navigation";
import './fonts/AntipastoPro-DemiBold_trial.ttf';
import './fonts/AntipastoPro-Extralight_trial.ttf';
import './fonts/AntipastoPro-Medium_trial.ttf';
// @ts-ignore
import HttpsRedirect from 'react-https-redirect';


function App() {
    return (
        <HttpsRedirect>
            <div>
                <Navigation/>

            </div>
        </HttpsRedirect>

    );
}

export default App;
