import React from 'react';
import {Container, Row, Button} from 'react-bootstrap';
import {Link} from "react-router-dom";


const Footer = () => {
    return (
        <div >
            <footer style={{ minHeight: '3vh'}} className="bg-dark text-white ">
                <Container style={{textAlign: 'center'}}>
                    <Row>
                        <Link to="/impressum">
                            <Button variant="link" className="text-white"
                                    style={{textDecoration: 'none', fontFamily: 'Medium', fontSize: '18px'}}>
                                Impressum
                            </Button>
                        </Link>
                    </Row>
                </Container>
            </footer>
        </div>

    );
}

export default Footer;
